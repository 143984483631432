<template>
  <Loader :value="promises.fetch" class="contact-page">
    <Blocks :blocks="config" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  mixins: [BrowserApiMixin('contact')],
  computed: {
    ...mapState('Page', ['promises', 'item']),
    config() {
      return config.call(this)
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('getInTouch'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  h1 {
    margin-top: 0;
  }

  .contact-lead-section {
    @include respond-below('tablet') {
      --section-padding-inline: 0rem;
    }

    .contact-lead {
      padding: 0;

      @include respond-above('tablet') {
        gap: 3rem;
        padding: 2rem 0;
      }

      @include respond-below('tablet') {
        grid-template-columns: 1fr;
      }

      &__image {
        img {
          background-color: inherit;
        }
      }

      .kpb-lead {
        padding: 1rem;
        max-width: 700px;
      }

      .lead__form {
        --vue-elder-primary-border: white;
        background-color: inherit;
        margin-top: 1rem;

        .elder-input__label {
          font-weight: normal;
          color: var(--primary-contrast);
        }
        .elder-checkbox__box {
          border-color: var(--vue-elder-primary-border);
        }

        &-title {
          h2 {
            margin: 0;
          }
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 1.5rem;
        }

        &-actions button {
          width: 100%;
        }
      }
    }
  }
}
</style>
